import React from 'react';
import { IconContext } from 'react-icons';
import { FaEnvelope } from 'react-icons/fa';

const Email = () => {
  return (
    <div>
      <IconContext.Provider
        value={{ style: { fontSize: '30px', color: 'rgb(0, 0, 0)' } }}
      >
        <div>
          <FaEnvelope />
        </div>
      </IconContext.Provider>
    </div>
  );
};

export default Email;
