import {
  Box,
  Center,
  Heading,
  Text,
  Stack,
  Avatar,
  useColorModeValue,
} from '@chakra-ui/react';
import profile from '../../Pictures/profile.png';

const Article = (props) => {
  return (
    <Center py={6}>
      <Box
        maxW="445px"
        w="full"
        bg={useColorModeValue('white', 'gray.900')}
        boxShadow="2xl"
        rounded="md"
        p={6}
        overflow="hidden"
        borderRadius="md"
      >
        {props.image && (
          <Box
            h="210px"
            bg="gray.100"
            mt={-6}
            mx={-6}
            mb={6}
            pos="relative"
          />
        )}
        <Stack>
          <Text
            color="green.500"
            textTransform="uppercase"
            fontWeight={800}
            fontSize="sm"
            letterSpacing={1.1}
          >
            {props.type}
          </Text>
          <Heading
            color={useColorModeValue('gray.700', 'white')}
            fontSize="2xl"
            fontFamily="body"
          >
            {props.title}
          </Heading>
          <Text color="gray.500">{props.description}</Text>
        </Stack>
        <Stack mt={6} direction="row" spacing={4} align="center">
          <Avatar src={profile} alt="Author" />
          <Stack direction="column" spacing={0} fontSize="sm">
            <Text fontWeight={600}>{props.author}</Text>
            <Text color="gray.500">{props.date}</Text>
            <Text color="gray.500">{props.minutes} min read</Text>
          </Stack>
        </Stack>
      </Box>
    </Center>
  );
};

export default Article;
