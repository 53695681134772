import React from 'react';
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
} from '@chakra-ui/react';

const Modal = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen} alignItems="center">
        {props.modalTitle}
      </Button>

      <ChakraModal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent width="500px" height="500px" alignItems="left">
          <ModalHeader alignItems="Center">
            {props.modalTitle}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>{props.modalBody}</ModalBody>
          <ModalFooter>
            {props.primaryAction && (
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                {props.primaryAction}
              </Button>
            )}
            {props.secondaryAction && (
              <Button variant="ghost" onClick={onClose}>
                {props.secondaryAction}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </ChakraModal>
    </>
  );
};

export default Modal;
