/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Image } from '@chakra-ui/react';

const Picture = (props) => {
  return (
    <Image
      borderRadius={props.borderRadius}
      boxSize={props.size}
      src={props.src}
      alt={props.name}
      marginTop="10"
    />
  );
};

// Picture.defaultProps = {
// borderRadius: "full",
// boxSize:"20px",
// src:"https://bit.ly/sage-adebayo",
// alt:"Segun Adebayo"

// };

export default Picture;
