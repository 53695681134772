/* eslint-disable*/
import React from 'react';
import {
  Box,
  Container,
  Heading,
  HStack,
  Text,
  VStack,
  Link,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';

const Article1 = () => {
  return (
    <Container>
      <VStack spacing="10px" align="left">
        <Heading align="center" padding="30px">
          WTF Is this Site?
        </Heading>
        <VStack spacing="40px">
          <Text>
            <Heading
              as="h2"
              size="md"
              paddingBottom="15px"
              paddingTop="15px"
            >
              Who am I:
            </Heading>
            I am primarily a backend developer turning front end
            developer. I have always been interested in the front end,
            but with all the new frameworks popping up I was afraid to
            commit. Within the year, my team in my professional role
            committed to React and there started my journey to the
            wonderful world of React and thus this site!
          </Text>

          <Text>
            <Heading
              as="h2"
              size="md"
              paddingBottom="15px"
              paddingTop="15px"
            >
              How I am learning React:
            </Heading>
            <Link
              href="https://www.udemy.com/course/react-the-complete-guide-incl-redux/"
              isExternal
              size="true"
            >
              React: The Complete Guide Including Redux
              <ExternalLinkIcon mx="2px" />
            </Link>
            <br />I learn best by taking courses and following along.
            I thought it was a good idea to throw some money at a
            course so I would be more dedicated, and of course Udemy
            had their “every other day sale” so I couldn’t resist. In
            my opinion, the course was good, but not great. The course
            is a little dry at times, but it is broken out into great
            chapters and modules. I wish I would have done more
            research on beginner courses, but this is one of the first
            ones that popped up. I’m planning to take some more
            courses on react in the near future and am looking forward
            to sharing how those are. I owe a lot to my team for
            showing me the ropes to Production worthy React and asking
            dumb questions that I am now ashamed of.
          </Text>
          <Text>
            <Heading
              as="h2"
              size="md"
              paddingBottom="20px"
              paddingTop="20px"
            >
              What is this site going to be?
            </Heading>
            A catch-all personal website with blog posts consisting of
            technology, what I am using, product reviews, and what I
            am currently working on professionally. Don’t expect there
            to be a blog post every week but expect there to be an
            update or code change every so often.
          </Text>

          <Text>
            <Heading
              as="h2"
              size="md"
              paddingBottom="15px"
              paddingTop="15px"
            >
              How this site was built?
            </Heading>
            I built this site using a CRA and the Chakra-UI component
            library. I am a huge fan of not re-inventing the wheel. As
            I rollout new features, I am sure new packages will be
            added. It is currently deployed through Netlify.
          </Text>

          <Text>
            <Heading
              as="h2"
              size="md"
              paddingBottom="15px"
              paddingTop="15px"
            >
              What I learned from building this site?
            </Heading>
            I learned how versatile and easy to use React components
            really are. The options are limitless, and there is so
            much already built within React to work off of (again,
            huge fan of not re-inventing the wheel). It was hard to
            commit to building this site in the beginning, but now
            that it has become something more cohesive, my site is
            live, and I have a clearer vision of what I want it to be,
            it’s exciting to work on and I’ve been enjoying it more
            and more.
          </Text>
        </VStack>
      </VStack>
    </Container>
  );
};

export default Article1;
