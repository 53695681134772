import React from 'react';
import Modal from '../Modal';

// This component represents the intial rendering of the submit feedback form with a modal
// where the feedback form is passed into the modal
const SubmitFeedback = (props) => {
  return (
    <Modal
      modalTitle="Submit Feedback"
      // secondaryAction="Close"
      // primaryAction="Submit"
      modalBody={props.modalBody}
    />
  );
};

export default SubmitFeedback;
