import React from 'react';
import { Container, Heading } from '@chakra-ui/react';
import { Carousel } from 'react-responsive-carousel';
import SiteCard from '../Components/UI/SiteCard';
import ComingSoon from '../Pictures/ComingSoon.png';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

const CurrentProject = () => {
  return (
    <>
      <Container centerContent height="100vh">
        <Heading as="h3" size="lg" paddingTop="5">
          Current Projects
        </Heading>
        <Carousel padding="550px">
          <SiteCard websiteImage={ComingSoon} />
          <br />
        </Carousel>
      </Container>
    </>
  );
};

export default CurrentProject;
