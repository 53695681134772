import React from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Heading } from '@chakra-ui/react';
import Article from '../Components/UI/Article';

const Blog = () => {
  return (
    <>
      <Container centerContent height="100vh">
        <Heading as="h3" size="lg" paddingTop="5">
          Current Projects
        </Heading>

        <NavLink to="/Blog1">
          <Article
            image={false}
            type="Blog"
            title="WTF is this site?"
            description="Introduction to myself, this site, and what to expect"
            author="Sam Charney"
            date="July 2nd 2021"
            minutes="5"
          />
        </NavLink>
      </Container>
    </>
  );
};

export default Blog;
