import React from 'react';
import { IconContext } from 'react-icons';
import { FaLinkedin } from 'react-icons/fa';

const LinkedIn = () => {
  return (
    <IconContext.Provider
      value={{
        style: { fontSize: '30px', color: 'rgb(0, 123, 255)' },
      }}
    >
      <div>
        <FaLinkedin />
      </div>
    </IconContext.Provider>
  );
};

export default LinkedIn;
