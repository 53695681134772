import React from 'react';
import { Flex } from '@chakra-ui/react';

const Footer = (props) => {
  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      mb={0}
      p={8}
      bg="teal.500"
      color={['black', 'black', 'primary.700', 'primary.700']}
      {...props}
    />
  );
};

export default Footer;
