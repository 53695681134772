import { ChakraProvider } from '@chakra-ui/react';
import './App.css';
import { Switch, Route, Redirect } from 'react-router-dom';
import About from './Screens/About';
import Blog from './Screens/Blog';
import Uses from './Screens/Uses';

import Header from './Components/UI/Header';
import Footer from './Components/UI/Footer';
import CurrentProject from './Screens/CurrentProject';
import Blog1 from './Screens/BlogPosts/Blog1';
import NotFound from './Screens/NotFound';
import SubmitFeedback from './Components/UI/Feedback/SubmitFeedback';
import FeedbackForm from './Components/UI/Feedback/FeedbackForm';

function App() {
  return (
    <ChakraProvider>
      <Header />
      <Switch>
        <Route exact path="/">
          <Redirect to="/about" />
        </Route>
        <Route exact path="/about">
          <About />
        </Route>
        <Route exact path="/blog">
          <Blog />
        </Route>
        <Route exact path="/currentprojects">
          <CurrentProject />
        </Route>
        <Route exact path="/uses">
          <Uses />
        </Route>
        <Route exact path="/Blog1">
          <Blog1 />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
      <Footer>
        <SubmitFeedback modalBody={<FeedbackForm />} />
      </Footer>
    </ChakraProvider>
  );
}

export default App;
