/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {
  Box,
  Container,
  Heading,
  HStack,
  Text,
  VStack,
  Link,
} from '@chakra-ui/react';
import Picture from '../Components/UI/Picture';
import profile from '../Pictures/profile.png';
import Twitter from '../Components/UI/Icons/Twitter';
import LinkedIn from '../Components/UI/Icons/LinkedIn';
import Email from '../Components/UI/Icons/Email';
// import Article from '../Components/UI/Article';

const About = () => {
  return (
    <>
      <Container centerContent height="auto" width="auto">
        <Box boxSize="lrg" paddingBottom="200px">
          <Container>
            <HStack>
              <Box boxSize="sm">
                <Picture
                  borderRadius="full"
                  boxSize="75px"
                  src={profile}
                />
              </Box>
              <VStack spacing="10px" align="center">
                <Heading align="center">Sam Charney</Heading>
              </VStack>
            </HStack>
            <VStack padding="30PX">
              <Text align="center">
                I'm a Software Engineer in Memphis, TN.
              </Text>
              <HStack align="left" spacing="50px">
                <Link href="https://twitter.com">
                  <Twitter />
                </Link>
                <Link href="https://www.linkedin.com/in/sam-charney-936652135/">
                  <LinkedIn />
                </Link>
                <Link href="mailto: sac915@gmail.com">
                  <Email />
                </Link>
              </HStack>
            </VStack>

            <Container>
              <VStack spacing="10px" align="left">
                <Heading>About</Heading>
                <VStack spacing="40px">
                  <Text>
                    I'm currently a Software Engineer at St. Jude's
                    Children's Research Hospital fundraising
                    organization, ALSAC, in Memphis, TN. I work
                    primarly on data synchronzations through APIs and
                    Microservices in addition to end user experiences.
                    I studied Computer Science at the University of
                    Memphis. Recently I've been working on cloud
                    computing, distributed applications through
                    Microservices, and exploring new uses of React.
                    Previously, I've worked at Geico focusing on data
                    integrations and building user interfaces for the
                    integrations.
                  </Text>
                  <Text>
                    When I'm not coding, I enjoy 🏋️‍♂️ working out, 🥾
                    hiking, 🚗 cars, 🧽 autodetailing, and 👨‍🍳 cooking.
                  </Text>
                </VStack>
              </VStack>
            </Container>
          </Container>
        </Box>
      </Container>
    </>
  );
};

export default About;
