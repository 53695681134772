import { useForm } from 'react-hook-form';
import React, { useState } from 'react';
import {
  FormErrorMessage,
  FormControl,
  Input,
  Button,
  VStack,
  Textarea,
  Alert,
  AlertIcon,
  AlertDescription,
  AlertTitle,
  CloseButton,
} from '@chakra-ui/react';

import emailjs from 'emailjs-com';

export default function HookForm() {
  const {
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  const [modalOpen, setModalOpen] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [errorMessage, setErrorMessage] = useState('');
  const [error1, setError1] = useState(false);

  function sendEmail(e) {
    // reset error message
    setErrorMessage('');
    setError1(false);
    e.preventDefault();

    // emails going proxy through email js
    emailjs
      .sendForm(
        'service_xrdi125',
        'template_3opuunj',
        e.target,
        'user_sEM0CDoM8QMmzc896zwg6',
      )

      .then(
        // eslint-disable-next-line no-unused-vars
        (result) => {
          setErrorMessage('');
          setError1(false);
        },
        (error) => {
          setErrorMessage(error.text);
          setError1(true);
        },
      );

    setModalOpen(false);
  }

  return (
    <form onSubmit={sendEmail}>
      {modalOpen && (
        <FormControl isInvalid={errors.name}>
          <VStack padding="10px">
            <Input
              placeholder="First Name"
              {...register('firstName', {
                required: 'First name is required',
                maxLength: 20,
              })}
            />
            <Input
              placeholder="Last Name"
              {...register('lastName', {
                required: 'First name is required',
                pattern: /^[A-Za-z]+$/i,
              })}
            />
            <Input
              placeholder="Email Address"
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Please enter a valid email',
                },
              })}
              type="email"
              required
              className="input"
            />

            <Textarea
              placeholder="Feedback"
              {...register('feedback', {
                required: true,
                maxLength: 500,
              })}
            />
          </VStack>

          <FormErrorMessage>
            {errors.name && errors.name.message}
          </FormErrorMessage>
        </FormControl>
      )}

      {modalOpen && (
        <Button
          mt={4}
          colorScheme="teal"
          isLoading={isSubmitting}
          type="submit"
        >
          Submit
        </Button>
      )}
      {/* error Text here */}
      {error1 && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle mr={2}>Error in sending feedback</AlertTitle>
          <AlertDescription>Please retry again</AlertDescription>
          <CloseButton position="absolute" right="8px" top="8px" />
        </Alert>
      )}

      {!modalOpen && (
        <Button
          mt={4}
          colorScheme="teal"
          isLoading={isSubmitting}
          type="submit"
          isDisabled="True"
        >
          Submitted
        </Button>
      )}
    </form>
  );
}
