/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  Center,
  Image,
} from '@chakra-ui/react';
import DeskSetup from '../Pictures/DeskSetup.jpg';

const Uses = () => {
  return (
    <>
      <Box paddingTop="50px">
        <Center>
          <Heading as="h1" size="xl" padding="auto">
            Tools that I use:
          </Heading>
        </Center>

        <VStack alignItems="left">
          <VStack alignItems="left">
            <Box>
              <Heading as="h4" size="lg" centerContent padding="7px">
                🖥️ Hardware
              </Heading>
              <VStack alignItems="left" padding="7px">
                <Text>
                  💻 Personal: Apple Macbook Pro (15-inch, 2017)
                </Text>
                <Text>💻 Work: Dell Inspiron 15</Text>
                <Text>⌨️ Keyboard: Drop Alt Cherry MX Brown</Text>
                <Text>🖱️ Mouse: Logitech MX Master 2S</Text>
                <Text>📺 Monitor: Lenovo Q24h-10 23.8-inch QHD</Text>
                <Text>🎧 Headphones: AirPods V1(x3)</Text>
                <Text>💺 Chair: Herman Miller Embody</Text>
                <Text>iPad: 2018 10.2" iPad</Text>
              </VStack>
            </Box>
          </VStack>

          <Box>
            <Heading as="h4" size="lg" centerContent padding="7px">
              👨🏻‍💻 Development
            </Heading>
            <VStack alignItems="left" padding="7px">
              <Text>
                VSCode for front end projects. I am not a power user
                of it by any means, but I think it's one of the better
                ecosystems available.
              </Text>
              <Text>
                Visual Studio for backend projects. I will admit this
                is slow (even without resharper enabled), but there is
                something I love about Visual Studio and its classic
                feel.
              </Text>
              <Text>
                SSMS for MS SQL Server with RedGate SQL Prompt
                Pro(Lifesaver)
              </Text>
              <Text>
                GIT: Command Line. It is on my long list to become a
                GIT ninja
              </Text>
            </VStack>
          </Box>

          <Box>
            <Heading as="h4" size="lg" centerContent padding="7px">
              ✅ Productivity
            </Heading>
          </Box>

          <VStack alignItems="left" padding="7px">
            <Text>
              ToDoist: Todoist is my favorite todo application. It
              suits my needs very well: it’s cross platform, has an
              open API, and supports complex repeated tasks
            </Text>
          </VStack>
        </VStack>
      </Box>
      <Center>
        <Image
          boxSize="450px"
          borderRadius="50px"
          padding="15px"
          objectFit="fill"
          src={DeskSetup}
        />
      </Center>
    </>
  );
};

export default Uses;
