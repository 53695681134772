import React from 'react';
import { IconContext } from 'react-icons';
import { FaTwitter } from 'react-icons/fa';

const Twitter = () => {
  return (
    <IconContext.Provider
      value={{
        style: { fontSize: '30px', color: 'rgb(0, 123, 255)' },
      }}
    >
      <div>
        <FaTwitter />
      </div>
    </IconContext.Provider>
  );
};

export default Twitter;
