/* eslint-disable react/jsx-one-expression-per-line */
import {
  Box,
  Center,
  Heading,
  Text,
  Stack,
  Avatar,
  useColorModeValue,
  Image,
} from '@chakra-ui/react';

import ComingSoon from '../../Pictures/ComingSoon.png';
import profile from '../../Pictures/profile.png';

const SiteCard = () => {
  return (
    <Center py={6}>
      <Box
        maxW="445px"
        w="full"
        bg={useColorModeValue('white', 'gray.900')}
        boxShadow="xl"
        rounded="lrg"
        borderRadius="20px"
        p={6}
        overflow="hidden"
      >
        <Box
          h="410px"
          bg="gray.100"
          mt={-6}
          mx={-6}
          mb={6}
          pos="relative"
        >
          <Image
            borderRadius="90px"
            padding="15px"
            objectFit="fill"
            src={ComingSoon}
          />
        </Box>
        <Stack>
          <Text
            color="blue"
            textTransform="uppercase"
            fontWeight={800}
            fontSize="sm"
            letterSpacing={1.1}
          >
            Website
          </Text>
          <Heading
            color={useColorModeValue('gray.700', 'white')}
            fontSize="2xl"
            fontFamily="body"
          >
            Product Review Website
          </Heading>
          <Text color="gray.500">
            An addicitve and informative review website. The goal is
            to provide
            <strong> no BS, short, and concise</strong> product
            reviews and recommendations.
          </Text>
        </Stack>
        <Stack mt={6} direction="row" spacing={4} align="center">
          <Avatar src={profile} alt="Author" />
          <Stack direction="column" spacing={0} fontSize="sm">
            <Text fontWeight={600}>Sam Charney</Text>
            <Text color="gray.500">
              Release Date : <strong>TBD</strong>
            </Text>
          </Stack>
        </Stack>
      </Box>
    </Center>
  );
};

export default SiteCard;
